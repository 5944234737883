import React, { useState, useEffect } from "react";
import webContext from "./webContext";
import required from "../required";
const AuthState = (props) => {
  const [HomeInfo, setHomeInfo] = useState({});
  const [HomeInfoLoading, setHomeInfoLoading] = useState({});
  const [ShowProgressBar, setShowProgressBar] = useState(false);
  const getHomeInfo = async () => {
    let response = await fetch(required.backhost + "/api/home", {
      method: "GET",
    });

    let data = await response.json();
    setHomeInfo(data);
    setHomeInfoLoading(false);
  };

  useEffect(() => {
    getHomeInfo();
    // eslint-disable-next-line
  }, []);
  return (
    // eslint-disable-next-line
    <webContext.Provider
      value={{ HomeInfo, HomeInfoLoading, ShowProgressBar, setShowProgressBar }}
    >
      {props.children}
    </webContext.Provider>
  );
};

export default AuthState;
