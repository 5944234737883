import { ImageList, ImageListItem, Skeleton } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import required from "../../required";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import webContext from "../../context/webContext";
const Gallery = () => {
  const { setShowProgressBar } = useContext(webContext);
  const [Images, setImages] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [FileName, setFileName] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const getImage = async () => {
    setShowProgressBar(true);
    setLoading(true);
    let images = [];
    let response = await fetch(required.backhost + "/api/home/gallery", {
      method: "GET",
    });
    response = await response.json();
    response.data.forEach((element) => {
      images = images.concat({ img: element.url, title: element.fileName });
    });
    setImages(images);
    setLoading(false);
    setShowProgressBar(false);
  };
  useEffect(() => {
    getImage();
    // eslint-disable-next-line
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      key={"gallery"}
    >
      <Helmet>
        <title>Gallery</title>
      </Helmet>
      <AnimatePresence>
        <div className="lg:m-4 m-2">
          {Loading ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ease: "easeOut", duration: 0.5 }}
              className="flex flex-row flex-wrap "
            >
              <div className="m-2">
                <Skeleton height={300} width={300} />
              </div>
              <div className="m-2">
                <Skeleton height={300} width={300} />
              </div>
              <div className="m-2">
                <Skeleton height={300} width={300} />
              </div>
              <div className="m-2">
                <Skeleton height={300} width={300} />
              </div>
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ease: "easeOut", duration: 0.5 }}
              className="flex flex-row flex-wrap "
            >
              <ImageList
                variant="masonry"
                cols={window.screen.width > 1000 ? 6 : 3}
                gap={2}
              >
                {Images.map((item) => (
                  <ImageListItem
                    onClick={() => {
                      setFileName(
                        item.title.slice(item.title.indexOf("AmAn123") + 7)
                      );
                      setSelectedId(item.img);
                    }}
                    className="cursor-pointer"
                    key={item.img}
                  >
                    <LazyLoadImage
                      src={`${item.title}`}
                      srcSet={`${item.img}`}
                      alt={item.title}
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </motion.div>
          )}
        </div>
        <div>
          {selectedId && (
            <motion.div
              initial={{
                scale: 0,
                opacity: 0,
              }}
              animate={{
                scale: 1,
                opacity: 1,
              }}
              exit={{ scale: 0, opacity: 0 }}
              transition={{
                duration: 0.3,
              }}
              style={{
                zIndex: "99999",
                backgroundColor: "#000000b5",
              }}
              onClick={() => {
                setSelectedId(null);
              }}
              className="fixed top-0 left-0 h-screen w-screen flex justify-center items-center overflow-hidden"
              layoutId={selectedId}
            >
              <FontAwesomeIcon
                className="text-white text-xl fixed top-2 right-2 cursor-pointer"
                icon={faTimes}
              />
              <div
                className="absolute top-0 left-0 text-white text-xl p-2"
                style={{
                  backgroundColor: "#00000054",
                }}
              >
                {FileName.slice(0, FileName.lastIndexOf("."))}
              </div>
              <img
                className="m-0 p-0 md:h-4/5 md:w-auto w-full h-auto"
                src={selectedId}
                alt="BannerImage"
              />
            </motion.div>
          )}
        </div>
      </AnimatePresence>
    </motion.div>
  );
};

export default Gallery;
