import { Skeleton } from "@mui/material";
import React from "react";
import { motion } from "framer-motion";
const CardSkeletionNormal = () => {
  return (
    <motion.div
    className="h-96"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0 }}
    >
      <div className="flex w-80  m-1 mt-3 justify-between ">
        <div>
          <Skeleton variant="circular" height={50} width={50} />
        </div>
        <div>
          <Skeleton height={50} width={250} />
          <Skeleton height={40} width={250} />
          <Skeleton height={20} width={100} />
        </div>
      </div>
      <div className="flex w-80 m-1 mt-3 justify-between ">
        <div>
          <Skeleton variant="circular" height={50} width={50} />
        </div>
        <div>
          <Skeleton height={50} width={250} />
          <Skeleton height={40} width={250} />
          <Skeleton height={20} width={100} />
        </div>
      </div>
      <div className="flex w-80 m-1 mt-3 justify-between ">
        <div>
          <Skeleton variant="circular" height={50} width={50} />
        </div>
        <div>
          <Skeleton height={50} width={250} />
          <Skeleton height={40} width={250} />
          <Skeleton height={20} width={100} />
        </div>
      </div>
    </motion.div>
  );
};

export default CardSkeletionNormal;
