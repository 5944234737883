import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./routes/home";
import RoutesWrapper from "./components/RoutesWrapper";
import Info from "./routes/info";
import Error404 from "./components/404/Error404";
import Footer from "./components/footer";
import Type from "./routes/type";
import Search from "./routes/search";
import Contact from "./routes/contact";
import Gallery from "./routes/gallery";
import AuthState from "./context/webState";

function App() {
  return (
    <div className="App">
      <AuthState>
        <Router>
          <RoutesWrapper>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/info/:id" element={<Info />} />
            <Route exact path="/type/:id" element={<Type />} />
            <Route
              exact
              path="/search/:searchterm&t=:type"
              element={<Search />}
            />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/gallery" element={<Gallery />} />
            <Route exact path="/404" element={<Error404 />} />
            <Route path="/*" element={<Error404 />} />
          </RoutesWrapper>
          <Footer />
        </Router>
      </AuthState>
    </div>
  );
}

export default App;
