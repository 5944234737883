import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import React from "react";
import {  Skeleton } from "@mui/material";
import CardWithDate from "../../../components/card/CardWithDate";
const Left = ({ Info, Loading }) => {
  return (
    <>
      <h2 className="m-2 text-2xl border-b inline border-black">
        News/ Events
      </h2>
      <div className="mx-auto m-1 ">
        <div className="w-full">
          <div className="w-full mx-1">
            {/* start */}
            {Loading ? (
              <>
                <div className="flex w-full m-1 mt-3 ">
                  <div>
                    <Skeleton variant="circular" height={50} width={50} />
                  </div>
                  <div className="w-full ml-2">
                    <Skeleton height={50} width={"70%"} />
                    <Skeleton height={40} width={"90%"} />
                    <Skeleton height={30} width={100} />
                  </div>
                </div>
                <div className="flex w-full m-1 mt-3 ">
                  <div>
                    <Skeleton variant="circular" height={50} width={50} />
                  </div>
                  <div className="w-full ml-2">
                    <Skeleton height={50} width={"70%"} />
                    <Skeleton height={40} width={"90%"} />
                    <Skeleton height={30} width={100} />
                  </div>
                </div>
                <div className="flex w-full m-1 mt-3 ">
                  <div>
                    <Skeleton variant="circular" height={50} width={50} />
                  </div>
                  <div className="w-full ml-2">
                    <Skeleton height={50} width={"70%"} />
                    <Skeleton height={40} width={"90%"} />
                    <Skeleton height={30} width={100} />
                  </div>
                </div>
              </>
            ) : (
              <>
                {Info.NewsEvents.map((data) => {
                  return (
                    <CardWithDate
                      key={data._id}
                      id={data._id}
                      slug={data.slug}
                      title={data.title}
                      description={data.description}
                      date={data.date}
                    />
                  );
                })}
              </>
            )}
            {/* end */}
            <div className="flex justify-center">
              <Link to="/type/NewsEvents" className="flex justify-center mx-2 ">
              <span className="relative inline-flex items-center justify-center p-2 px-3 py-1.5 overflow-hidden font-medium text-indigo-600 transition duration-300 ease-out border-2 border-indigo-500 rounded-full shadow-md group">
              <span className="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-indigo-500 group-hover:translate-x-0 ease">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  ></path>
                </svg>
              </span>
              <span className="absolute flex items-center justify-center w-full h-full text-indigo-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                View All
              </span>
              <span className="relative invisible">View All</span>
            </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="flex flex-row flex-wrap">
          <div>
            {window.screen.width > 768 ? (
              <iframe
                title="facebookEmbade"
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fbalewacampus&tabs=timeline&width=400&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                width="400"
                height="500"
                style={{ border: "none", overflow: "hidden" }}
                scrolling="no"
                frameBorder="0"
                allowFullScreen={true}
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              ></iframe>
            ) : (
              <iframe
                title="facebookEmbade"
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fbalewacampus&tabs=timeline&width=300&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                width="300"
                height="500"
                style={{ border: "none", overflow: "hidden" }}
                scrolling="no"
                frameBorder="0"
                allowFullScreen={true}
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              ></iframe>
            )}
          </div>
          <div className="self-center ml-4">
            <div>
              <h2 className="m-2 text-xl border-b inline border-black">
                RESULTS
              </h2>
              <br />
              <a
                rel="noreferrer"
                className=" text-indigo-500 hover:text-indigo-600 inline-flex items-center cursor-pointer mx-1"
                href="https://tuexam.edu.np/"
                target="_blank"
              >
                <FontAwesomeIcon icon={faAngleRight} /> &nbsp;
                <span>TU Result</span> &nbsp;
              </a>
            </div>
            <div className="mt-4">
              <h2 className="m-2  text-xl border-b inline border-black">
                USEFUL LINKS
              </h2>
              <br />
              <div className="flex flex-col">
                <a
                  rel="noreferrer"
                  className=" text-indigo-500 hover:text-indigo-600 inline-flex items-center cursor-pointer mx-1"
                  href="https://tuexam.edu.np/"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faAngleRight} /> &nbsp;
                  <span>Universities of Nepal</span> &nbsp;
                </a>
                <a
                  rel="noreferrer"
                  className=" text-indigo-500 hover:text-indigo-600 inline-flex items-center cursor-pointer mx-1"
                  href="http://www.tuservicecommission.edu.np/"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faAngleRight} /> &nbsp;
                  <span>TU Service Commission</span> &nbsp;
                </a>
                <a
                  rel="noreferrer"
                  className=" text-indigo-500 hover:text-indigo-600 inline-flex items-center cursor-pointer mx-1"
                  href="http://www.ugcnepal.edu.np/"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faAngleRight} /> &nbsp;
                  <span>University Grants Commission</span> &nbsp;
                </a>
                <a
                  rel="noreferrer"
                  className=" text-indigo-500 hover:text-indigo-600 inline-flex items-center cursor-pointer mx-1"
                  href="http://www.moe.gov.np/"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faAngleRight} /> &nbsp;
                  <span>Ministry of Education</span> &nbsp;
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Left;
