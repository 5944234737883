// const required = {
//   fronthost: "http://localhost:3000",
//   backhost: "http://localhost:3001",
//   name: "BPMC",
//   CAPTCHA_CLIENT_SITE_KEY: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
//   AllTypes: [
//     "All",
//     "Download",
//     "NewsEvents",
//     "CallForPaper",
//     "GeneralNotice",
//     "DepartmentalNotice",
//   ],
// };

// export default required
const required  = {
    fronthost: "https://campus.smilepant.com",
    backhost: "https://campus.smilepant.com/api",
    name: "BPMC",
    CAPTCHA_CLIENT_SITE_KEY:"6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
    AllTypes: [
        "All",
        "Download",
         "NewsEvents",
         "CallForPaper",
         "GeneralNotice",
         "DepartmentalNotice",

      ]
}

export default required;
