import Left from "./Left";
import Right from "./Right";

const Middle = ({ Info, Loading }) => {
  return (
    <div className="md:m-4 m-2 flex flex-wrap md:flex-nowrap">
      <div className="md:w-2/3 w-full mx-2 shadow-2xl p-2 rounded-lg bg-gray-100">
        <Left Loading={Loading} Info={Info} />
      </div>
      <div className="md:w-1/3 w-full mx-2 shadow-2xl p-2 rounded-lg bg-gray-100">
        <Right Loading={Loading} Info={Info} />
      </div>
    </div>
  );
};

export default Middle;
