import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "./components/Dropdown";

const Content = ({ setShowNav }) => {
  return (
    <>
      <Link
        onClick={() => {
          if (setShowNav) {
            setShowNav(false);
          }
        }}
        to="/"
        className="mr-5 hover:text-gray-200 cursor-pointer mx-1 hover:border-b border-gray-300 transform duration-300"
      >
        Home
      </Link>
      <Dropdown name="Programs">
        <Link
          onClick={() => {
            if (setShowNav) {
              setShowNav(false);
            }
          }}
          to="/info/63038e472b2f0199e451c0d2"
        >
          Bachelors Programs
        </Link>
      </Dropdown>
      <Dropdown name="Campus">
        <Link
          onClick={() => {
            if (setShowNav) {
              setShowNav(false);
            }
          }}
          to="/info/management-committee"
        >
          Management Committee
        </Link>
        <Link
          onClick={() => {
            if (setShowNav) {
              setShowNav(false);
            }
          }}
          to="/info/adminstration-staff"
        >
          Adminstration Staff
        </Link>
        <Link
          onClick={() => {
            if (setShowNav) {
              setShowNav(false);
            }
          }}
          to="/info/teaching-staff"
        >
          Teaching Staff
        </Link>
        <Link
          onClick={() => {
            if (setShowNav) {
              setShowNav(false);
            }
          }}
          to="/info/non-teaching-staff"
        >
          Non-Teaching Staff
        </Link>
      </Dropdown>
      <Dropdown name="About Us">
        <Link
          onClick={() => {
            if (setShowNav) {
              setShowNav(false);
            }
          }}
          to="/info/history"
        >
          History
        </Link>
        <Link
          onClick={() => {
            if (setShowNav) {
              setShowNav(false);
            }
          }}
          to="/info/mission-and-vision"
        >
          Mission And Vision
        </Link>
      </Dropdown>
      <Link
        onClick={() => {
          if (setShowNav) {
            setShowNav(false);
          }
        }}
        to="/type/Download"
        className="mr-5 hover:text-gray-200 cursor-pointer mx-1 hover:border-b border-gray-300 transform duration-300"
      >
        Download
      </Link>
      <Link
        onClick={() => {
          if (setShowNav) {
            setShowNav(false);
          }
        }}
        to="/contact"
        className="mr-5 hover:text-gray-200 cursor-pointer mx-1 hover:border-b border-gray-300 transform duration-300"
      >
        Contact
      </Link>
      <Link
        onClick={() => {
          if (setShowNav) {
            setShowNav(false);
          }
        }}
        to="/gallery"
        className="mr-5 hover:text-gray-200 cursor-pointer mx-1 hover:border-b border-gray-300 transform duration-300"
      >
        Gallery
      </Link>
    </>
  );
};

export default Content;
