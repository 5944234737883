import React from "react";
import "react-multi-carousel/lib/styles.css";
import { Skeleton } from "@mui/material";
import { motion } from "framer-motion";
import Marquee from "react-fast-marquee";
import Carousel from "./Carousel";
import CallToAction from "../components/CallToAction";
const Top = ({ Info, Loading }) => {
  return (
    <>
      <div className="  overflow-hidden ">
        {Loading ? (
          <Skeleton variant="text" width={"100vw"} height={30} />
        ) : (
          <motion.div
            key={"asdio4r9ic"}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ ease: "easeIn", duration: 0.3 }}
            className="flex bg-indigo-500"
          >
            <div className="bg-red-500 text-white p-1 pr-2 ">Notice</div>
            <div className="pt-1 text-white  w-full">
              <Marquee delay={4} pauseOnHover={true} gradientWidth={0}>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                {Info.info.headLineNotice}
              </Marquee>
            </div>
          </motion.div>
        )}
        <div className="home-image-slider shadow w-full  ">
          <Carousel />
        </div>
        {!Loading && Info.info.callForAction.content !== "" && (
          <CallToAction data={Info.info.callForAction} />
        )}
        <div>
          <motion.div>
            {Loading ? (
              <div className="m-2">
                <Skeleton variant="rectangular" width={"100%"} height={40} />
                <br />
                <Skeleton variant="rectangular" width={"100%"} height={120} />
              </div>
            ) : (
              <>
                <div className="lg:w-3/4 mx-auto md:my-4">
                  <h1 className="text-3xl font-bold border-b pb-1 p-2 mt-3 text-left">
                    {Info.info["campusChiefMessage"].title.toUpperCase()}
                  </h1>
                  <div className="flex flex-col md:flex-row m-2 justify-between">
                    <div className="flex md:flex-col  md:w-1/3">
                      {Info.info["campusChiefMessage"].image.map((link) => {
                        return (
                          <img
                            key={link} 
                            alt="MESSAGE FROM THE CAMPUS CHIEF"
                            className="mx-auto my-1 h-auto md:w-auto  relative transform duration-300 rounded"
                            src={link}
                          />
                        );
                      })}
                    </div>
                    <motion.div
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{ duration: 0.3 }}
                      viewport={{ once: true }}
                      className="m-2 md:w-2/3 bg-slate-200 rounded"
                    >
                      <div
                        className="p-2"
                        dangerouslySetInnerHTML={{
                          __html: Info.info["campusChiefMessage"].content,
                        }}
                      ></div>
                    </motion.div>
                  </div>
                </div>
                <div className="lg:w-3/4 mx-auto md:my-4">
                  <h1 className="text-3xl font-bold border-b pb-1 p-2 mt-3 text-right">
                    {Info.info["HistoryInfo"].title.toUpperCase()}
                  </h1>
                  <div className="flex flex-col-reverse md:flex-row m-2 justify-between">
                    <motion.div
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{ duration: 0.3 }}
                      viewport={{ once: true }}
                      className="m-2 sm:w-2/3 bg-slate-200 rounded"
                    >
                      <div
                        className="p-2"
                        dangerouslySetInnerHTML={{
                          __html: Info.info["HistoryInfo"].content,
                        }}
                      ></div>
                    </motion.div>
                    <div className="flex flex-col  md:w-1/3">
                      {Info.info["HistoryInfo"].image.map((link) => {
                        return (
                          <img
                          alt="HISTORICAL BACKGROUND"
                            key={link}
                            className="mx-auto my-1 h-auto md:w-auto relative transform duration-300 rounded"
                            src={link}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </>
            )}
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Top;
