import React, { useContext, useEffect, useState } from "react";
import required from "../../required";
import logo from "../../image/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import {
  faEnvelope,
  faPhone,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import Content from "./Content";
import webContext from "../../context/webContext";
const Desktop = () => {
  const { ShowProgressBar } = useContext(webContext);
  const { location } = useLocation();
  const [width, setWidth] = useState(60);
  let navigate = useNavigate();
  const [searchterm, setSearchterm] = useState("");
  const search = (e) => {
    e.preventDefault();
    if (searchterm.length > 0) {
      navigate("/search/" + searchterm + "&t=All");
    }
  };
  useEffect(() => {
    if (ShowProgressBar) {
      if (width < 90) {
        setTimeout(() => {
          setWidth(width + 5);
        }, 500);
      }
    } else {
      setWidth(60);
    }
    // eslint-disable-next-line
  }, [width, location, ShowProgressBar]);
  return (
    <>
      <div className="bg-indigo-600 text-white h-10 flex items-center">
        <div style={{ marginLeft: "10%" }}>
          <span className="mx-3">
            <FontAwesomeIcon icon={faPhone} />
            &nbsp; <a href="tel:068524315">Contact: 068-524315</a>
          </span>

          <span className="mx-3">
            <FontAwesomeIcon icon={faEnvelope} />
            &nbsp;
            <a href="mailto:bpmc2047@gmail.com">Email: bpmc2047@gmail.com</a>
          </span>
        </div>
      </div>
      <header
        className="text-gray-600 body-font shadow-lg bg-indigo-500 sticky top-0"
        style={{ zIndex: "99999" }}
      >
        <div className="container flex flex-wrap p-2 flex-col md:flex-row items-center">
          <Link
            to="/"
            className="flex title-font font-medium items-center text-white mb-4 md:mb-0"
          >
            <img src={logo} alt="logo" className="w-10" />
            <span className="ml-3 text-xl">{required.name}</span>
          </Link>
          <nav className="md:mr-auto md:ml-4 text-white md:py-1 md:pl-4 md:border-l md:border-gray-400	flex flex-wrap items-center text-base justify-center">
            <Content />
          </nav>
          <div>
            <form className="flex items-center" onSubmit={search}>
              <div className="relative text-gray-600">
                <input
                  value={searchterm}
                  onChange={(e) => {
                    setSearchterm(e.target.value);
                  }}
                  type="search"
                  name="serch"
                  placeholder="Search"
                  className="bg-white h-10 px-5 pr-10 rounded-full text-sm focus:outline-none"
                />
                <button
                  type="submit"
                  className="absolute right-0 top-0 mt-2 mr-4"
                >
                  <div className="h-4 w-4 fill-current">
                    <FontAwesomeIcon icon={faSearch} />
                  </div>
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="sticky top-0 ">
          <AnimatePresence>
            {ShowProgressBar && (
              <motion.div
                initial={{
                  opacity: 0,
                  width: 0,
                }}
                animate={{
                  opacity: 1,
                  width: `${width}vw`,
                }}
                exit={{
                  width: "99vw",
                }}
                transition={{
                  duration: 0.4,
                }}
                style={{ height: 2 }}
                className=" bg-black absolute shadow shadow-gray-500 rounded "
              />
            )}
          </AnimatePresence>
        </div>
      </header>
    </>
  );
};

export default Desktop;
