import React, { useState } from "react";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../image/logo.png";
import Content from "./Content";
const Mobile = () => {
  let navigate = useNavigate();
  const [ShowNav, setShowNav] = useState(false);
  const [searchterm, setSearchterm] = useState("");
  const search = (e) => {
    e.preventDefault();
    if (searchterm.length > 0) {
      navigate("/search/" + searchterm + "&t=All");
    }
  };
  return (
    <div>
      <motion.div
        id="side-menu"
        className="fixed top-0 w-[240px] h-screen z-50 bg-gray-200 p-4 flex flex-col justify-start space-y-5 text-black transform duration-300"
        style={{
          zIndex: "100002",
          left: ShowNav ? "0" : "-300px",
        }}
      >
        <span
          className="text-right text-2xl"
          onClick={() => {
            setShowNav(false);
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
        <div className="border-b border-gray-400 shadow-sm">
          <img className="w-24  mx-auto" src={logo} alt="logo" />
          <p className="text-center">BPMC- Balewa Paiyunpata Multiple Campus</p>
        </div>
        <Content setShowNav={setShowNav} />
      </motion.div>
      <div className="flex justify-end bg-indigo-500 text-white shadow-sm text-lg">
        <Link
          className="mr-5 hover:text-gray-200 cursor-pointer mx-1 hover:border-b border-gray-300 transform duration-300"
          to="/"
        >
          Home
        </Link>
        <Link
          className="mr-5 hover:text-gray-200 cursor-pointer mx-1 hover:border-b border-gray-300 transform duration-300"
          to="/type/All"
        >
          Notice
        </Link>
        <Link
          className="mr-5 hover:text-gray-200 cursor-pointer mx-1 hover:border-b border-gray-300 transform duration-300"
          to="/type/Download"
        >
          Download
        </Link>
      </div>
      <div
        className=" bg-indigo-500 flex justify-end p-2 w-full"
        style={{ zIndex: "10001" }}
      >
        <span
          onClick={() => {
            setShowNav(true);
          }}
          style={{ zIndex: "10002" }}
          className="fixed top-2 left-2  p-1 ml-2 text-sm font-medium text-white bg-gray-500 opacity-60 rounded-sm border focus:ring-4 focus:outline-none "
        >
          <FontAwesomeIcon className="text-xl text-white px-1" icon={faBars} />
        </span>
        <form className="mx-auto " onSubmit={search}>
          <div className="relative text-gray-600">
            <input
              value={searchterm}
              onChange={(e) => {
                setSearchterm(e.target.value);
              }}
              type="search"
              name="serch"
              placeholder="Search"
              className="bg-white h-10 px-5 pr-10 rounded-full text-sm focus:outline-none"
            />
            <button type="submit" className="absolute right-0 top-0 mt-2 mr-4">
              <div className="h-4 w-4 fill-current">
                <FontAwesomeIcon icon={faSearch} />
              </div>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Mobile;
