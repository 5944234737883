import React from "react";
import required from "../../../required";
import { Link } from "react-router-dom";
import "./CallToaction.css";
const CallToAction = ({ data }) => {
  return (
    <div>
      <div className=" m-auto my-2 px-6 ">
        <div className="flex flex-col md:flex-row items-center justify-between    shadow-2xl rounded-lg border border-gray-200 p-8">
          <div className=" text-2xl w-full">
            <svg
              className="float-left mr-1"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="17"
              viewBox="0 0 20 17"
              fill="none"
            >
              <path
                d="M0 12.321C0 14.6267 1.72927 16.356 4.03496 16.356C6.34065 16.356 8.06992 14.6267 8.06992 12.321C8.06992 10.5197 7.06118 9.07866 5.47602 8.50224L8.64634 0H5.54807L1.08079 9.07866C0.432317 10.4477 0 11.3123 0 12.321ZM11.3123 12.321C11.3123 14.6267 13.0416 16.356 15.3473 16.356C17.6529 16.356 19.3822 14.6267 19.3822 12.321C19.3822 10.5197 18.3014 9.07866 16.7883 8.50224L19.8866 0H16.8604L12.3931 9.07866C11.6726 10.4477 11.3123 11.3123 11.3123 12.321Z"
                fill="#4f46e5"
              />
            </svg>
            <div className="w-full">{data.content}</div>
          </div>
          {data.redirect.indexOf(required.fronthost) > -1 ? (
            <Link className="w-40"  to={data.redirect.replace(required.fronthost, "")}>
               <div className="relative px-5 py-2  font-medium text-white group">
                <span className="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform translate-x-0 -skew-x-12 bg-indigo-500 group-hover:bg-indigo-700 group-hover:skew-x-12"></span>
                <span className="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform skew-x-12 bg-indigo-700 group-hover:bg-indigo-500 group-hover:-skew-x-12"></span>
                <span className="absolute bottom-0 left-0 hidden w-10 h-20 transition-all duration-100 ease-out transform -translate-x-8 translate-y-10 bg-indigo-600 -rotate-12"></span>
                <span className="absolute bottom-0 right-0 hidden w-10 h-20 transition-all duration-100 ease-out transform translate-x-10 translate-y-8 bg-indigo-400 -rotate-12"></span>
                <span className="relative">Learn More</span>
              </div>
            </Link>
          ) : (
            <a className="w-40" href={data.redirect}>
              <div className="relative px-5 py-2  font-medium text-white group">
                <span className="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform translate-x-0 -skew-x-12 bg-indigo-500 group-hover:bg-indigo-700 group-hover:skew-x-12"></span>
                <span className="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform skew-x-12 bg-indigo-700 group-hover:bg-indigo-500 group-hover:-skew-x-12"></span>
                <span className="absolute bottom-0 left-0 hidden w-10 h-20 transition-all duration-100 ease-out transform -translate-x-8 translate-y-10 bg-indigo-600 -rotate-12"></span>
                <span className="absolute bottom-0 right-0 hidden w-10 h-20 transition-all duration-100 ease-out transform translate-x-10 translate-y-8 bg-indigo-400 -rotate-12"></span>
                <span className="relative">Learn More</span>
              </div>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
