import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";

import "./Caurousel.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
const sliderVariants = {
  incoming: (direction) => ({
    x: direction > 0 ? "100%" : "-100%",
    scale: 1.2,
    opacity: 0,
  }),
  active: { x: 0, scale: 1, opacity: 1 },
  exit: (direction) => ({
    x: direction > 0 ? "-100%" : "100%",
    scale: 1,
    opacity: 0.2,
  }),
};

const sliderTransition = {
  duration: 1,
  ease: [0.56, 0.03, 0.12, 1.04],
};
const IMAGES = [
  {
    id: 0,
    imageSrc:
      "https://images.unsplash.com/photo-1603573355706-3f15d98cf100?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1329&q=80",
    titleTop: "Welcome to",
    titleBottom: "Campus",
    button: {
      text: "Contact us",
      link: "/contact",
    },
  },
  {
    id: 1,
    imageSrc:
      "https://images.unsplash.com/photo-1576495199011-eb94736d05d6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1172&q=80",
    titleTop: "Empower Minds",
    titleBottom: "Ignite Futures!",
    button: {
      text: "About Us",
      link: "/info/history",
    },
  },
  {
    id: 2,
    imageSrc:
      "https://images.unsplash.com/photo-1532649538693-f3a2ec1bf8bd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    titleTop: "Unlock Your Potential, ",
    titleBottom: "Learn to Soar!",
    button: {
      text: "Learn More",
      link: "/info/mission-and-vision",
    },
  },
];

const Carousel = () => {
  const [[imageCount, direction], setImageCount] = useState([0, 0]);
  const [ticking] = useState(true),
    [count, setCount] = useState(0);
  const [allowSlide, setAllowSlide] = useState(true);
  const activeImageIndex = wrap(0, IMAGES.length, imageCount);

  const swipeToImage = (swipeDirection) => {
    setImageCount([imageCount + swipeDirection, swipeDirection]);
  };

  const dragEndHandler = (dragInfo) => {
    const draggedDistance = dragInfo.offset.x;
    const swipeThreshold = 50;
    if (draggedDistance > swipeThreshold) {
      swipeToImage(-1);
    } else if (draggedDistance < -swipeThreshold) {
      swipeToImage(1);
    }
  };

  // const skipToImage = (imageId) => {
  //   let changeDirection;
  //   if (imageId > activeImageIndex) {
  //     changeDirection = 1;
  //   } else if (imageId < activeImageIndex) {
  //     changeDirection = -1;
  //   }
  //   setImageCount([imageId, changeDirection]);
  // };
   // eslint-disable-next-line
  useEffect(() => {
    if (allowSlide) {
      swipeToImage(1);
      const timer = setTimeout(() => ticking && setCount(count + 4), 4e3);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, [count, ticking]);

  return (
    <div
      className="main-container-slider"
      onMouseEnter={() => {
        setAllowSlide(false);
      }}
      onMouseLeave={() => {
        setAllowSlide(true);
      }}
    >
      <div className="slider-container" >
        <div className="slider">
          <AnimatePresence initial={false} custom={direction}>
            <motion.div
              key={imageCount}
              style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${IMAGES[activeImageIndex].imageSrc} )`,
              }}
              custom={direction}
              variants={sliderVariants}
              initial="incoming"
              animate="active"
              exit="exit"
              transition={sliderTransition}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(_, dragInfo) => dragEndHandler(dragInfo)}
              className="carousel-image "
            >
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}
                className=" relative top-1/3 md:left-52 left-12 text-white shadow"
              >
                <div>
                  <h3 className="text-lg">
                    {IMAGES[activeImageIndex].titleTop.toLocaleUpperCase()}
                  </h3>
                  <h2 className="text-4xl font-bold">
                    {IMAGES[activeImageIndex].titleBottom}
                  </h2>
                </div>
                <Link
                  className="rounded relative inline-flex group items-center justify-center px-3.5 py-2 m-1 cursor-pointer border-b-4 border-l-2 active:border-indigo-600 active:shadow-none shadow-lg bg-gradient-to-tr from-indigo-600 to-indigo-500 border-indigo-700 text-white"
                  to={IMAGES[activeImageIndex].button.link}
                >
                  <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-32 group-hover:h-32 opacity-10"></span>
                  <span className="relative">
                    {IMAGES[activeImageIndex].button.text}
                  </span>
                </Link>
              </motion.div>
            </motion.div>
          </AnimatePresence>
          <div className="button-container">
            <button className="button-prev" onClick={() => swipeToImage(-1)}>
              <FontAwesomeIcon icon={faAngleLeft} />
            </button>
            <button className="button-next" onClick={() => swipeToImage(1)}>
              <FontAwesomeIcon icon={faAngleRight} />
            </button>
          </div>
        </div>
      </div>

      {/* <div className="thumbnails">
        {IMAGES.map(image => (
          <div
            key={image.id}
            onClick={() => skipToImage(image.id)}
            className="thumbnail-container"
          >
            <img src={image.imageSrc} alt="Musician" />
            <div
              className={`active-indicator ${
                image.id === activeImageIndex ? "active" : null
              }`}
            />
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default Carousel;
