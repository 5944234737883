import React, { useContext, useEffect, useState } from "react";
import { Link,  useNavigate, useParams } from "react-router-dom";
import required from "../../required";
import { Helmet } from "react-helmet";
import { Skeleton } from "@mui/material";
import CardWithoutDate from "../../components/card/CardWithoutDate";
import "./info.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import webContext from "../../context/webContext";
import { Breadcrumbs } from "@mui/material";
const Info = () => {
  const { id } = useParams();
  const { setShowProgressBar } = useContext(webContext);
  let navigate = useNavigate();
  const [Data, setData] = useState([]);
  const [Loading, setLoading] = useState(true);
  const getData = async () => {
    setShowProgressBar(true);
    await setLoading(true);
    let response = await fetch(`${required.backhost}/api/info/post/${id}`, {
      method: "GET",
    });

    let data = await response.json();

    if (data.sucess) {
      setData(data);
      setLoading(false);
    } else {
      navigate("/404");
    }
    setShowProgressBar(false);
  };
  useEffect(() => {
    getData();

    // eslint-disable-next-line
  }, [id]);

  return (
    <motion.div
      key={"info" + id}
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container mx-auto flex flex-wrap py-6">
        <section className="w-full md:w-2/3 flex flex-col items-center px-3">
          <article className="flex flex-col shadow my-4 w-full">
            <div className="bg-white flex flex-col justify-start w-full p-6">
              {Loading ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="border-b border-gray-300">
                    <Skeleton height={60} width={300} />

                    <Skeleton className=" pb-4" height={40} width={200} />

                    <Skeleton height={30} width={100} />
                  </div>
                  <Skeleton height={200} width={"100%"} />
                  <Skeleton height={300} width={"100%"} />
                </motion.div>
              ) : (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Helmet>
                    <title>{Data.info.title}</title>
                  </Helmet>
                  <div className="border-b border-gray-300">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link underline="hover" color="inherit" to="/">
                        HOME
                      </Link>
                      <Link
                        underline="hover"
                        color="inherit"
                        to={`/type/${Data.info.type}`}
                      >
                        {Data.info.type}
                      </Link>
                      <Link
                        to={`/info/${id}`}
                        className="hover:text-indigo-500 transform duration-200"
                      >
                        {Data.info.title}
                      </Link>
                    </Breadcrumbs>
                    <Link
                      to={`/type/${Data.info.type}`}
                      className="text-blue-700 text-sm font-bold uppercase pb-4"
                    >
                      {Data.info.type
                        .replace(/([A-Z])/g, " $1")
                        .trim()
                        .toUpperCase()}
                    </Link>
                    <motion.h1
                      initial={{ x: -40 }}
                      animate={{ x: 0 }}
                      exit={{ x: -20 }}
                      transition={{ duration: 0.5, type: "just" }}
                      className="text-3xl font-bold hover:text-gray-700 pb-4"
                    >
                      {Data.info.title}
                    </motion.h1>
                    <motion.p
                      initial={{ x: -100 }}
                      animate={{ x: 0 }}
                      exit={{ x: -20 }}
                      transition={{ duration: 1, type: "spring" }}
                      className="text-sm pb-8 font-semibold"
                    >
                      {new Date(Data.info.date).toDateString()}
                    </motion.p>
                  </div>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5, type: "spring" }}
                    className="info-data-content"
                    dangerouslySetInnerHTML={{
                      __html: Data.info.content,
                    }}
                  ></motion.div>
                </motion.div>
              )}
              {!Loading &&
                Data.info.download &&
                Data.info.download.length > 0 &&
                Data.info.download[0] !== "" && (
                  <div>
                    <h2 className="text-2xl font-bold hover:text-gray-700 pb-4">
                      Downloads:
                    </h2>
                    <div className="flex flex-wrap">
                      {Data.info.download.map((data) => {
                        return (
                          <a
                            key={data}
                            href={data}
                            target="download"
                            className="inline-flex overflow-hidden text-black border-2 bg-gray-50 rounded group transform duration-300"
                          >
                            <span className="px-3.5 py-2 text-white bg-indigo-500 group-hover:bg-indigo-600 flex items-center justify-center">
                              <FontAwesomeIcon
                                className="text-2xl"
                                icon={faDownload}
                              />
                            </span>
                            <span className="pl-4 pr-5 py-2.5">
                              {data.slice(data.lastIndexOf("/") + 1)}
                            </span>
                          </a>
                        );
                      })}
                    </div>
                  </div>
                )}
            </div>
          </article>
        </section>
        <aside className="w-full md:w-1/3 flex flex-col items-center px-3">
          <div className="w-full bg-white shadow flex flex-col my-4 p-6">
            <div>
              <h2 className="m-2 text-xl border-b inline border-black">
                Latest Notices
              </h2>
              {Loading ? (
                <>
                  <Skeleton height={40} width={300} />
                  <Skeleton height={20} width={100} />
                  <Skeleton height={40} width={300} />
                  <Skeleton height={20} width={100} />
                  <Skeleton height={40} width={300} />
                  <Skeleton height={20} width={100} />
                  <Skeleton height={40} width={300} />
                  <Skeleton height={20} width={100} />
                </>
              ) : (
                <>
                  {Data.latest.map((data) => {
                    return (
                      <CardWithoutDate
                        key={data._id}
                        id={data._id}
                        title={data.title}
                        slug={data.slug}
                        description={data.description}
                        date={data.date}
                      />
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </aside>
      </div>
    </motion.div>
  );
};

export default Info;
