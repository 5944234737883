import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import required from "../../required";
import InfiniteScroll from "react-infinite-scroll-component";
import { Radio, Skeleton } from "@mui/material";
import CardWithDate from "../../components/card/CardWithDate";
import CardSkeletionNormal from "../../components/card/CardSkeletionNormal";
import { AnimatePresence, motion } from "framer-motion";
import webContext from "../../context/webContext";
const Search = () => {
  const { searchterm, type } = useParams();
  const { setShowProgressBar } = useContext(webContext);
  let location = useLocation();
  const [page, setPage] = useState(0);
  const [Sucess, setSucess] = useState(false);
  const [Response, setResponse] = useState([]);
  const [Data, setData] = useState([]);
  const [ResultFound, setResultFound] = useState(true);
  const [Type, setType] = useState(type);

  const getData = async () => {
    setShowProgressBar(true);
    setResultFound(true);
    let response = await fetch(
      `${required.backhost}/api/info/search/${searchterm}&t=${Type}&p=0`,
      {
        method: "GET",
      }
    );

    let data = await response.json();
    if (data.sucess) {
      setResponse(data);
      setData(data.data);
      await setSucess(true);
      setResultFound(true);
    } else {
      setResultFound(false);
    }
    setShowProgressBar(false);
  };
  useEffect(() => {
    setSucess(false);
    setPage(0);
    getData();
    // eslint-disable-next-line
  }, [location, searchterm, Type]);

  const fetchData = async () => {
    let response = await fetch(
      `${required.backhost}/api/info/search/${searchterm}&t=${Type}&p=${
        page + 1
      }`,
      {
        method: "GET",
      }
    );

    let data = await response.json();
    setData(Data.concat(data.data));
    setPage(page + 1);
  };
  return (
    <motion.div
      initial={{ x: -400, opacity: 0.5 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -20 }}
      transition={{ duration: 0.5 }}
      key={searchterm+type}
    >
      <AnimatePresence>
        <div className="md:w-2/3 w-full mx-auto shadow-2xl p-2 rounded-lg bg-gray-100">
          <h2 className="m-2 text-2xl border-b inline border-black">
            Search result for: {searchterm}
          </h2>
          <div>
            {required.AllTypes.map((data) => {
              return (
                <span key={data}>
                  <Link to={`/search/${searchterm}&t=${data}`}>
                    <Radio
                      checked={data === Type}
                      onClick={() => setType(data)}
                    />
                  </Link>
                  {data.replace(/([A-Z])/g, " $1").trim()}
                </span>
              );
            })}
            {!ResultFound && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                exit={{ opacity: 0 }}
                className="h-96 w-full  flex  items-center"
              >
                <h2 className="font-bold text-xl w-full text-center">
                  No results found for: "{searchterm}"
                </h2>
              </motion.div>
            )}
          </div>

          {!Sucess && ResultFound && <CardSkeletionNormal />}

          {Sucess && ResultFound && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              exit={{ opacity: 0 }}
            >
              <InfiniteScroll
                dataLength={Data.length} //This is important field to render the next data
                next={fetchData}
                hasMore={Data.length !== Response.total}
                loader={
                  <>
                    <motion.div
                      transition={{ duration: 0.5 }}
                      className="flex w-80 m-1 mt-3 ml-8 justify-between "
                    >
                      <div>
                        <Skeleton variant="circular" height={50} width={50} />
                      </div>
                      <div>
                        <Skeleton height={50} width={250} />
                        <Skeleton height={40} width={250} />
                        <Skeleton height={20} width={100} />
                      </div>
                    </motion.div>
                  </>
                }
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
              >
                {/* news and event start */}

                <div className="mx-auto m-1">
                  <div className="flex flex-wrap ">
                    <div className="w-full mx-1">
                      {/* start */}
                      {!Sucess ? (
                        <motion.div transition={{ duration: 0.5 }}>
                          <CardSkeletionNormal />
                        </motion.div>
                      ) : (
                        <>
                          {Data.map((data) => {
                            return (
                              <CardWithDate
                                key={data._id}
                                id={data._id}
                                title={data.title}
                                description={data.description}
                                date={data.date}
                                slug={data.slug}
                                type={data.type}
                              />
                            );
                          })}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </InfiniteScroll>
            </motion.div>
          )}
        </div>
      </AnimatePresence>
    </motion.div>
  );
};

export default Search;
