import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
const Cards = () => {
  const data = [
    {
      title: "Programs",
      imgSrc: require("../../../image/icon-box.jpg"),
      description:
        "When you choose to study at BPMC, you  just choosing a great campus .",
      link: "/info/admission-now",
    },
    {
      title: "Programs",
      imgSrc: require("../../../image/icon-box1.jpg"),
      description:
        "When you choose to study at BPMC, you are  choosing a great campus .",
      link: "/info/63038e472b2f0199e451c0d2",
    },
    {
      title: "Events",
      imgSrc: require("../../../image/icon-box2.jpg"),
      description:
        "    All the member ,staffs students of BPMC can have a notice about campus events.",
      link: "/type/NewsEvents",
    },
    {
      title: "News and Notice",
      imgSrc: require("../../../image/icon-box3.jpg"),
      description:
        "Every news , results, class schedule and campus information are posted here.",
      link: "/info/admission-now",
    },
  ];
  return (
    <div>
      <div className="flex justify-center md:flex-row flex-col items-baseline ">
        {data.map((info) => {
          return (
            <motion.div
            key={data.title}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.2 }}
              viewport={{ once: true }}
              className="md:w-1/4 w-80 rounded-xl m-4 md:mx-1 lg:mx-8  shadow-md hover:scale-105 transform duration-300"
            >
              <img className="rounded-xl " src={info.imgSrc} alt="image1" />
              <div className="text-center p-1">
                <h3 className="text-2xl font-semibold">{info.title}</h3>
                <p className="text-gray-500">{info.description}</p>
                <Link to={info.link}>
                  <span className="px-4 py-2 relative rounded group overflow-hidden font-medium bg-purple-50 text-indigo-600 inline-block">
                    <span className="absolute top-0 left-0 flex w-full h-0 mb-0 transition-all duration-200 ease-out transform translate-y-0 bg-indigo-600 group-hover:h-full opacity-90"></span>
                    <span className="relative group-hover:text-white">
                      LEARN MORE
                    </span>
                  </span>
                </Link>
              </div>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};

export default Cards;
