import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import React from "react";
import required from "../../required";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <div>
        <svg
          className="absolute overflow-hidden"
          style={{ zIndex: "-1" }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
        >
          <path
            fill="#6366f1"
            fillOpacity="0.8"
            d="M0,32L40,37.3C80,43,160,53,240,80C320,107,400,149,480,170.7C560,192,640,192,720,170.7C800,149,880,107,960,117.3C1040,128,1120,192,1200,197.3C1280,203,1360,149,1400,122.7L1440,96L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
          ></path>
        </svg>
        <footer className="footer sm:pt-16 text-gray-800">
          <div className="px-6 mx-auto space-y-6 divide-y divide-gray-400 md:space-y-12 divide-opacity-50">
            <div className="grid grid-cols-12">
              <div className="pb-6 col-span-full md:pb-0 md:col-span-4">
                <div className="flex justify-center space-x-3 md:justify-start">
                  <div className="flex items-center justify-center w-12 h-12 rounded-full">
                    <Link to="/" className="flex items-center py-4 ">
                      <img
                        src="/android-chrome-512x512.png"
                        alt="Logo"
                        className="h-12 w-12 mr-2"
                      />
                    </Link>
                  </div>
                  <div>
                    <span className="self-center text-2xl font-semibold">
                      {required.name}
                    </span>
                    <p>bpmc2047@gmail.com</p>
                    <p>068-524315</p>
                  </div>
                </div>
              </div>
              <div className="col-span-6 text-center md:text-left md:col-span-3">
                <p className="pb-1 text-lg font-medium">Campus</p>
                <ul>
                  <li>
                    <Link className=" hover:text-indigo-700" to="/info/management-committee">
                      Management Committee
                    </Link>
                  </li>
                  <li>
                    <Link className=" hover:text-indigo-700" to="/info/adminstration-staff">
                      Adminstration Staff
                    </Link>
                  </li>
                  <li>
                    <Link className=" hover:text-indigo-700" to="/info/teaching-staff">
                      Teaching Staff
                    </Link>
                  </li>
                  <li>
                    <Link className="hover:text-indigo-700" to="/info/non-teaching-staff">
                      Non-Teaching Staff
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-span-6 text-center md:text-left md:col-span-3">
                <p className="pb-1 text-lg font-medium">About Us</p>
                <ul>
                  <li>
                  <Link className="hover:text-indigo-700" to="/info/history">History</Link>
       
                  </li>
                  <li>
                  <Link className="hover:text-indigo-700" to="/info/mission-and-vision">Mission And Vision</Link>
                  </li>
                </ul>
              </div>
              <div className="col-span-6 text-center md:text-left md:col-span-2">
                <p className="pb-1 text-lg font-medium">Post</p>
                <ul>
                  <li>
                  <Link className="hover:text-indigo-700" to="/type/NewsEvents">News/ Events</Link>
       
                  </li>
                  <li>
                  <Link className="hover:text-indigo-700" to="/type/GeneralNotice">General Notice</Link>
                  </li>
                  <li>
                  <Link className="hover:text-indigo-700" to="/type/DepartmentalNotice">Departmental Notice</Link>
                  </li>
                  <li>
                  <Link className="hover:text-indigo-700" to="/type/CallForPaper">Call For Paper</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="grid justify-center pt-6 lg:justify-between">
              <div className="flex flex-col self-center text-sm text-center md:block lg:col-start-1 md:space-x-6">
                <span>
                  Copyright © {new Date().getFullYear()}&nbsp; 
                  {required.name} All rights reserved
                </span>
              </div>
              <div className="flex justify-center pt-4 space-x-4 lg:pt-0 lg:col-end-13">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/balewacampus/"
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};
export default Footer;
