import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import required from "../../required";
import InfiniteScroll from "react-infinite-scroll-component";
import CardWithDate from "../../components/card/CardWithDate";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SkeletonNormal from "./SkeletonNormal";
import SkeletonInfinite from "./SkeletonInfinite";
import CardSkeletionNormal from "../../components/card/CardSkeletionNormal";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import webContext from "../../context/webContext";
const Type = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  const { setShowProgressBar } = useContext(webContext);
  const [page, setPage] = useState(0);
  const [Sucess, setSucess] = useState(false);
  const [Response, setResponse] = useState([]);
  const [Data, setData] = useState([]);
  const [searchterm, setSearchterm] = useState("");
  const search = (e) => {
    e.preventDefault();
    if (searchterm.length > 0) {
      navigate(`/search/${searchterm}&t=${id} `);
    }
  };

  const getData = async () => {
    setShowProgressBar(true);
    let response = await fetch(
      `${required.backhost}/api/info/type/${id}&p=${page}`,
      {
        method: "GET",
      }
    );

    let data = await response.json();
    if (data.sucess) {
      setResponse(data);
      setData(data.data);
      setSucess(true);
    } else {
      navigate("/404");
    }
    setShowProgressBar(false);
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [id]);

  const fetchData = async () => {
    let response = await fetch(
      `${required.backhost}/api/info/type/${id}&p=${page + 1}`,
      {
        method: "GET",
      }
    );

    let data = await response.json();
    setData(Data.concat(data.data));
    setPage(page + 1);
  };
  return (
    <motion.div
      initial={{ y: 80, opacity: 0.5 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -20, opacity: 0 }}
      transition={{ duration: 0.5 }}
      key={"type" + id}
    >
      <Helmet>
        <title>{id.replace(/([A-Z])/g, " $1").trim()}</title>
      </Helmet>
      <div className="md:w-2/3 w-full mx-auto shadow-2xl p-2 rounded-lg bg-gray-100 transform duration-200">
        <h2 className="m-2 text-2xl border-b inline border-black">
          {id.replace(/([A-Z])/g, " $1").trim()}
        </h2>
        <motion.div
          initial={{ width: 0 }}
          animate={{ width: "auto" }}
          exit={{ x: -20 }}
          transition={{ duration: 0.5 }}
          className="m-2"
        >
          <form className="flex items-center" onSubmit={search}>
            <div className="flex w-full">
              <input
                value={searchterm}
                onChange={(e) => {
                  setSearchterm(e.target.value);
                }}
                type="text"
                placeholder="Search"
                className="px-4 w-full py-2 border border-gray-300 rounded-l-lg focus:outline-none focus:ring focus:border-blue-400"
              />
              <button
                type="submit"
                className="px-4 py-2 bg-indigo-500 text-white rounded-r-md hover:bg-indigo-600 focus:outline-none focus:ring focus:border-green-400"
              >
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </div>
          </form>
        </motion.div>
        {!Sucess && <SkeletonNormal />}
        {Sucess && (
          <InfiniteScroll
            dataLength={Data.length} //This is important field to render the next data
            next={fetchData}
            hasMore={Data.length !== Response.total}
            loader={
              <>
                <SkeletonInfinite />
              </>
            }
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            {/* news and event start */}

            <div className="mx-auto m-1">
              <div className="flex flex-wrap ">
                <div className="w-full mx-1">
                  {/* start */}
                  {!Sucess ? (
                    <CardSkeletionNormal />
                  ) : (
                    <>
                      {Data.map((data) => {
                        return (
                          <CardWithDate
                          key={data._id}
                          id={data._id}
                          title={data.title}
                          description={data.description}
                          slug={data.slug}
                            date={data.date}
                          />
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
          </InfiniteScroll>
        )}
      </div>
    </motion.div>
  );
};

export default Type;
