import { useState, useEffect } from "react";
import { Alert } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWifi} from "@fortawesome/free-solid-svg-icons";
import { AnimatePresence, motion } from "framer-motion";
function InternetChecker() {
  // Online state
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
      setShowAlert(true);
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  return (
    <AnimatePresence mode="popLayout" initial={true}>
      <motion.div className="fixed bottom-5 right-4">
        {isOnline ? (
          <>
            {showAlert && (
              <motion.div
                key={"have internet"}
                initial={{
                  x: 500,
                }}
                animate={{
                  x: 0,
                }}
                exit={{
                  x: 500,
                }}
                transition={{
                  duration: 0.5,
                  type: "spring"
                }}
              >
                <Alert
                  onClose={() => {
                    setShowAlert(false);
                  }}
                  icon={
                    <div>
                      <FontAwesomeIcon icon={faWifi} />
                    </div>
                  }
                  severity="success"
                >
                  Your internet connection was restored.
                </Alert>
              </motion.div>
            )}
          </>
        ) : (
          <>
            {showAlert && (
              <motion.div
                key={"dont have internet"}
                initial={{
                  x: 500,
                }}
                animate={{
                  x: 0,
                }}
                exit={{
                  x: 500,
                }}
                transition={{
                  duration: 0.5,
                  type: "spring"
                }}
              >
                <Alert
                  onClose={() => {
                    setShowAlert(false);
                  }}
                  icon={
                    <div className="after:w-0.5 after:h-6 after:bg-red-700 after:block after:absolute after:top-4 after:rounded after:left-7 after:rotate-12">
                      <FontAwesomeIcon icon={faWifi} />
                    </div>
                  }
                  severity="error"
                >
                  No internet, You are currently offline
                </Alert>
              </motion.div>
            )}
          </>
        )}
      </motion.div>
    </AnimatePresence>
  );
}

export default InternetChecker;
