import React, {  useEffect, useState } from "react";
import { Routes, useLocation } from "react-router-dom";
import { AnimatePresence,  } from "framer-motion";
import Navigation from "./Navbar";
import InternetChecker from "./InternetChecker";
const RoutesWrapper = ({ children }) => {
  const [prevLoc, setPrevLoc] = useState("");
  const location = useLocation();

  useEffect(() => {
    setPrevLoc(location.pathname);
    if (location.pathname === prevLoc) {
      setPrevLoc("");
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    // eslint-disable-next-line
  }, [location]);
  return (
    <>
      <AnimatePresence mode="wait" initial={false}>
        <Navigation />
        <Routes>{children}</Routes>
        <InternetChecker/>
      </AnimatePresence>
    </>
  );
};

export default RoutesWrapper;
