import React, { useState } from "react";
import "./contact.css";
import { isMobile } from "react-device-detect";
import required from "../../required";
import { Alert, Snackbar, TextareaAutosize } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
const Contact = () => {
  const [Sucess, setSucess] = useState(false);
  const [sent, setSent] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [Response, setResponse] = useState("");
  const sendMessage = async () => {
    let headersList = {
      Accept: "*/*",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      name,
      email,
      message,
      captcha,
    });

    let response = await fetch(required.backhost + "/api/home/contact", {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    let data = await response.json();
    setSent(true);
    setSucess(data.sucess);
    setResponse(data.message);
  };

  const subContact = async (e) => {
    e.preventDefault();
    await setSent(false);
    if ((name || email || message) === "") {
      console.log("fill all fields");
    } else if (captcha === "") {
      setSent(true);
      setSucess(false);
      setResponse("Fill captcha first");
    } else {
      sendMessage();
    }
  };

  const handleClose = () => {
    setSent(false);
  };
  return (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
   
      transition={{ duration: 0.5 }}
      key={"contact"}
    >
      <Helmet>
        <title>Contact Us</title>
      </Helmet>
      <section className="text-gray-600 body-font relative">
        <div className="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
          <div className=" bg-gray-300 rounded-lg overflow-hidden sm:mr-10 h-fit">
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe
                  title="map"
                  width={isMobile ? window.screen.width : "100%"}
                  height={isMobile ? "100%" : "600"}
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=Balewa+multiple+collage&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                ></iframe>
                <a href="https://123movies-to.org">123movies</a>
                <br />\
              </div>
            </div>
          </div>
          <form
            onSubmit={subContact}
            className="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0"
          >
            <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">
              Contact Us
            </h2>
            <p className="leading-relaxed mb-5 text-gray-600">
              Email: bpmc2047@gmail.com
            </p>
            <div className="relative mb-4">
              <label for="name" className="leading-7 text-sm text-gray-600">
                Name
              </label>
              <input
                required
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                type="text"
                id="name"
                name="name"
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="relative mb-4">
              <label for="email" className="leading-7 text-sm text-gray-600">
                Email
              </label>
              <input
                required
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                type="email"
                id="email"
                name="email"
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="relative mb-4">
              <label for="message" className="leading-7 text-sm text-gray-600">
                Message
              </label>
              <TextareaAutosize
                required
                minRows={6}
                id="message"
                name="message"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                style={{
                  height: "auto",
                }}
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200  text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
              ></TextareaAutosize>
            </div>
            <ReCAPTCHA
              sitekey={required.CAPTCHA_CLIENT_SITE_KEY}
              onChange={(value) => {
                setCaptcha(value);
              }}
            />
            {/* <button className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">
              Send
            </button> */}
            <div className="cursor-pointer relative px-5 py-3 m-1 overflow-hidden font-medium text-indigo-600 bg-indigo-100 border border-indigo-100 rounded-lg shadow-inner group text-center">
              <span className="absolute top-0 left-0 w-0 h-0 transition-all duration-200 border-t-2 border-indigo-600 group-hover:w-full ease "></span>
              <span className="absolute bottom-0 right-0 w-0 h-0 transition-all duration-200 border-b-2 border-indigo-600 group-hover:w-full ease "></span>
              <span className="absolute top-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-indigo-600 group-hover:h-full ease "></span>
              <span className="absolute bottom-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-indigo-600 group-hover:h-full ease "></span>
              <span className="absolute inset-0 w-full h-full duration-300 delay-300 bg-indigo-600 opacity-0 group-hover:opacity-100 "></span>
              <span className="relative transition-colors duration-300 delay-200 group-hover:text-white ease ">
                Send
              </span>
            </div>
          </form>
        </div>
      </section>
      <div>
        <Snackbar open={sent} autoHideDuration={6000} onClose={handleClose}>
          {Sucess ? (
            <Alert
              onClose={handleClose}
              sx={{ width: "100%" }}
              severity="success"
            >
              {Response}
            </Alert>
          ) : (
            <Alert
              onClose={handleClose}
              sx={{ width: "100%" }}
              severity="error"
            >
              {Response}
            </Alert>
          )}
        </Snackbar>
      </div>
    </motion.div>
  );
};

export default Contact;
