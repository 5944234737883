import { Link } from "react-router-dom";
import React from "react";
import { Skeleton } from "@mui/material";
import CardWithoutDate from "../../../components/card/CardWithoutDate";
const Right = ({ Info, Loading }) => {
  return (
    <div className="flex flex-col sm:flex-row mt-2">
      <div className=" w-full mx-2  p-2 rounded-lg bg-gray-100">
        <div>
          <h2 className="m-2 text-xl border-b inline border-black">
            General Notice
          </h2>
          {Loading ? (
            <>
              <Skeleton height={40} width={300} />
              <Skeleton height={20} width={100} />
              <Skeleton height={40} width={300} />
              <Skeleton height={20} width={100} />
            </>
          ) : (
            <>
              {Info.GeneralNotice.map((data) => {
                return (
                  <CardWithoutDate
                    key={data._id}
                    id={data._id}
                    slug={data.slug}
                    title={data.title}
                    description={data.description}
                    date={data.date}
                  />
                );
              })}
            </>
          )}
          <Link
            to={`/type/GeneralNotice/`}
            className="flex justify-center m-2 "
          >
        <span className="relative inline-flex items-center justify-center p-2 px-3 py-1.5 overflow-hidden font-medium text-indigo-600 transition duration-300 ease-out border-2 border-indigo-500 rounded-full shadow-md group">
              <span className="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-indigo-500 group-hover:translate-x-0 ease">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  ></path>
                </svg>
              </span>
              <span className="absolute flex items-center justify-center w-full h-full text-indigo-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                View All
              </span>
              <span className="relative invisible">View All</span>
            </span>
          </Link>
        </div>
        <div className="mt-4 ">
          <h2 className="m-2 text-xl border-b inline border-black">
            Departmental Notice
          </h2>
          {Loading ? (
            <>
              <Skeleton height={40} width={300} />
              <Skeleton height={20} width={100} />
              <Skeleton height={40} width={300} />
              <Skeleton height={20} width={100} />
            </>
          ) : (
            <>
              {Info.DepartmentalNotice.map((data) => {
                return (
                  <CardWithoutDate
                    key={data._id}
                    id={data._id}
                    title={data.title}
                    description={data.description}
                    slug={data.slug}
                    date={data.date}
                  />
                );
              })}
            </>
          )}
          <Link
            to={`/type/DepartmentalNotice/`}
            className="flex justify-center m-2 "
          >
        <span className="relative inline-flex items-center justify-center p-2 px-3 py-1.5 overflow-hidden font-medium text-indigo-600 transition duration-300 ease-out border-2 border-indigo-500 rounded-full shadow-md group">
              <span className="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-indigo-500 group-hover:translate-x-0 ease">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  ></path>
                </svg>
              </span>
              <span className="absolute flex items-center justify-center w-full h-full text-indigo-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                View All
              </span>
              <span className="relative invisible">View All</span>
            </span>
          </Link>
        </div>
        <div className="mt-4 ">
          <h2 className="m-2 text-xl border-b inline border-black">
            Call For Paper
          </h2>
          {Loading ? (
            <>
              <Skeleton height={40} width={300} />
              <Skeleton height={20} width={100} />
              <Skeleton height={40} width={300} />
              <Skeleton height={20} width={100} />
            </>
          ) : (
            <>
              {Info.CallForPaper.map((data) => {
                return (
                  <CardWithoutDate
                    key={data._id}
                    id={data._id}
                    title={data.title}
                    slug={data.slug}
                    description={data.description}
                    date={data.date}
                  />
                );
              })}
            </>
          )}
          <Link
            className="relative  text-lg group flex justify-center m-2"
            to={`/type/CallForPaper/`}

          >
            <span className="relative inline-flex items-center justify-center p-2 px-3 py-1.5 overflow-hidden font-medium text-indigo-600 transition duration-300 ease-out border-2 border-indigo-500 rounded-full shadow-md group">
              <span className="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-indigo-500 group-hover:translate-x-0 ease">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  ></path>
                </svg>
              </span>
              <span className="absolute flex items-center justify-center w-full h-full text-indigo-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                View All
              </span>
              <span className="relative invisible">View All</span>
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Right;
