import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import React from "react";

const CardWithDate = ({ date, title, description, id, type, slug }) => {
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.1 }}
        viewport={{ once: true }}
        className="flex border-2  w-full rounded-lg border-gray-300 mx-1 my-2 border-opacity-50 px-2 py-4 flex-row items-center hover:shadow-xl transform duration-300"
      >
        <div className="w-20 h-20  sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0">
          <div className="flex flex-col justify-center items-center">
            <span className="text-2xl border-b border-black">
              {new Date(date).getDate()}
            </span>
            <span className="text-xs">
              {new Date(date).toLocaleString("default", {
                month: "short",
              })}, {new Date(date).getFullYear()}
            </span>
          </div>
        </div>
        <div className="ml-1">
          {type && (
            <Link to={"/type/" + type}>
              <span className="text-indigo-500 block text-xs font-medium">
                {type
                  .replace(/([A-Z])/g, " $1")
                  .trim()
                  .toUpperCase()}
              </span>
            </Link>
          )}
          <Link to={slug  ?`/info/${slug}`:`/info/${id}`}>
            <h2 className="text-gray-900 text-lg title-font font-medium ">
              {title}
            </h2>
          </Link>
          <p className="leading-relaxed text-base m-1">
            {description.substring(0, 150)}
          </p>
          <Link
            to={slug ?`/info/${slug}`:`/info/${id}`}
            className=" bg-indigo-500 text-white p-2 rounded hover:bg-indigo-600 inline-flex items-center cursor-pointer transform duration-300"
          >
            <span>Read More</span> &nbsp;
            <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </div>
      </motion.div>
    </>
  );
};

export default CardWithDate;
