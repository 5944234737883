import { Skeleton } from '@mui/material'
import React from 'react'

const SkeletonInfinite = () => {
  return (
    <div className="flex w-80 m-1 mt-3 ml-8 justify-between ">
    <div>
      <Skeleton variant="circular" height={50} width={50} />
    </div>
    <div>
      <Skeleton height={50} width={250} />
      <Skeleton height={40} width={250} />
      <Skeleton height={20} width={100} />
    </div>
  </div>
  )
}

export default SkeletonInfinite