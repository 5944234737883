import { faArrowRight, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const CardWithoutDate = ({ date, title, description, id, slug }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.2 }}
      viewport={{ once: true }}
      className="flex mt-2 p-2 items-baseline shadow-lg border border-gray-300 rounded"
    >
      <FontAwesomeIcon className="text-indigo-500" icon={faPaperPlane} />
      &nbsp;
      <div className="p-2">
      <Link to={`/info/${id}`}>
            <h2 className="text-gray-900 text-lg title-font font-medium mb-1">
              {title}
            </h2>
          </Link>
        <span className="text-gray-900 text-sm font-medium">
          {new Date(date).toDateString()}
        </span>
        <p>{description.substring(0, 150)}....</p>
        <Link
          to={slug ?`/info/${slug}`:`/info/${id}`}
          className=" bg-indigo-500 text-white p-2 rounded hover:bg-indigo-600 inline-flex items-center cursor-pointer transform duration-300"
          >
          <span>Read More</span> &nbsp;
          <FontAwesomeIcon icon={faArrowRight} />
        </Link>
      </div>
    </motion.div>
  );
};

export default CardWithoutDate;
