import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import "./Dropdown.css";
const Dropdown = ({ name, children }) => {
  const [showDropDown, setshowDropDown] = useState(false);
  return (
    <div
      onMouseLeave={() => {
        setshowDropDown(false);
      }}
    >
      <div className="dropdown">
        <button
          onMouseEnter={() => {
            setshowDropDown(true);
          }}
          className="dropbtn  "
        >
          <span className="lg:hover:text-gray-200 hover:border-b mr-1  border-white transform duration-300">
            {name}
          </span>
          <FontAwesomeIcon icon={faCaretDown} />
        </button>
        <AnimatePresence>
          {showDropDown && (
            <motion.div
              initial={{ height: 0, opacity: 1, fontSize: "1px" }}
              animate={{ height: "auto", opacity: 1, fontSize: "16px" }}
              transition={{ ease: "easeOut" }}
              exit={{ height: 0, opacity: 0, fontSize: "1px" }}
              className="dropdown-content"
            >
              {children}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Dropdown;
