import React, { useEffect,  useContext } from "react";

import "./home.css";
import Middle from "./Middle";
import Top from "./Top";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import Cards from "./components/Cards";
import webContext from "../../context/webContext";
const Home = () => {
  const { HomeInfo, HomeInfoLoading, setShowProgressBar } =
    useContext(webContext);
  useEffect(() => {
    setShowProgressBar(false);
    // eslint-disable-next-line
  }, []);

  return (
    <motion.div key={"home"}>
      <Helmet>
        <title>BPMC- Balewa Paiyunpata Multiple Campus</title>
      </Helmet>
      <Top Info={HomeInfo} Loading={HomeInfoLoading} />
      {!HomeInfoLoading && (
        <>
          <Cards />
          <Middle Info={HomeInfo} Loading={HomeInfoLoading} />
        </>
      )}
    </motion.div>
  );
};

export default Home;
